* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #fff !important;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(-81deg, #400775, #a82155, #5f069b);
	background-size: 700% 700%;
	animation: gradient 18s ease-in-out infinite;
  height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  overflow: hidden;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.btn-container {
  display: flex;
  justify-content: flex-start;
  padding: 5px 5px 5px 0px;

}

.btn {
  margin: 5px;
  padding: 5px;
  border-radius: 2px;
}

h1 {
  font-size: 36px;
}

.mt-20 {
  margin-top: 20px;
}

.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
