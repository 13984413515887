#about {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-class {
  word-wrap: break-word;
  padding: 8px;
  max-width: 92%;
}

a > span {
  color: #ae67e7 !important;
  transition:color .24s ease-in-out;
}

a > span:hover {
  color: #d27ce4 !important;
}

/* Larger Devices (Desktops, Laptops) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .about-class > h1 {
    font-size: 28px;
  }
  .about-class > h2 {
    font-size: 20px;
  }
}

/* Small Devices (Phones, Tablets) */
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .about-class > h1 {
    font-size: 24px;
  }

  .about-class > h2 {
    font-size: 16px;
  }

  .about-class .btn-container {
    align-items: center;
    flex-direction: column;
  }
}

/* Extra Small Devices (Older Phones) */
@media only screen and (max-width: 480px) {
  .about-class > h1 {
    font-size: 20px;
  }

  .about-class > h2 {
    font-size: 14px;
  }

  .about-class .btn-container {
    align-items: center;
    flex-direction: column;
  }
}

/* Larger Phones and Tablets (Landscape) */
@media only screen and (min-device-width: 769px) and (max-device-width: 1023px) and (orientation: landscape) {
  .about-class > h1 {
    font-size: 24px;
  }
  
  .about-class > h2 {
    font-size: 18px;
  }
}

/* XS / Smaller Phones (Landscape) */
@media only screen and (min-device-width: 480px) and (max-device-width: 768px) and (orientation: landscape) {
  .about-class > h1 {
    font-size: 20px;
  }
  
  .about-class > h2 {
    font-size: 14px;
  }
  .about-class .btn-container {
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
}
