#home {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-class {
  word-wrap: break-word;
  padding: 8px;
  max-width: 92%;
}

/* Larger Devices (Laptops, Tablets) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .home-class > h1 {
    font-size: 28px;
  }
  .home-class > h2 {
    font-size: 20px;
  }
}

/* Small Devices (Phones) */
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .home-class > h1 {
    font-size: 24px;
  }
  .home-class > h2 {
    font-size: 18px;
  }
}

/* Extra Small Devices (Older Phones) */
@media only screen and (max-width: 480px) {
  .home-class {
    text-align: center;
  }

  .home-class > h1 {
    font-size: 20px;
  }

  .home-class > h2 {
    font-size: 16px;
  }

  .home-class .btn-container {
    flex-direction: column;
  }
}
