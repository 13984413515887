#projects {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
}

.projects-class {
  word-wrap: break-word;
  padding: 8px;
  max-width: 92%;
  text-align: center;
}

.item {
  position: relative;
  background: #7a1bf7;
  overflow: hidden;
  min-width: 270px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.item::after {
  content: '';
  position: absolute;
  display: block;
  background: inherit;
  opacity: 0.9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
  transition: transform 1.5s cubic-bezier(0.2, 1, 0.3, 1);
}

.item:hover:after {
  transform: scale(2) translateX(0) translateY(0) rotate(-28deg);
}

.item:hover .item-image {
  transform: scale(1.2);
}

.item:hover .item-text {
  opacity: 1;
  transform: translateY(0); 
}

.item-image {
  height: auto;
  transform: translateZ(0);
  display: block;
  transition: transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
}

.item-image img {
  max-width: 100%;
  max-height: 100%;
}

.item-image:before {
  content: '';
  display: block;
  padding-top: 75%;
  overflow: hidden;
}

.item-image img {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  line-height: 0; 
}

.item-text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  text-align: center;
  z-index: 1;
  color: #fff;
  transform: translateY(-20%);
  transition: opacity 250ms cubic-bezier(0.2, 1, 0.3, 1), transform 400ms cubic-bezier(0.2, 1, 0.3, 1);
  transition-delay: 100ms;
  margin: 15px;
}

.item-text-wrap {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.item-text-title {
  padding: 0 1rem;
  margin: 5px 0 0 0;
}

.item-text-category {
  opacity: 0.75;
  margin: 0;
}

.item a {
  color: #fff;
}

.project-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Larger Devices (Desktops, Laptops) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .project-items {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

  .item {
    min-width: 200px;
  }

  .item-text {
    margin: 10px;
  }
  
  .item-text-title {
    padding: 0 0.5rem;
  }
  
  .item-text-wrap > h2 {
    font-size: 16px;
  }

  .item-text-wrap > p {
    font-size: 12px;
  }

  h5 {
    display: none;
  }
}

/* Small Devices (Phones, Tablets) */
@media only screen and (min-width: 481px) and (max-width: 767px) {
  #projects {
    overflow: scroll;
    height: 150vh;
  }

  .project-items {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
  }

  .item {
    height: 125px;
  }

  .item-text {
    margin: 5px;
  }
  
  .item-text-title {
    padding: 0 0.2rem;
  }
  
  .item-text-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    word-wrap: break-word;
  }

  .item-text-wrap > h2 {
    font-size: 12px;
  }

  .item-text-wrap > p {
    display: none;
  }

  h5 {
    font-size: 10px;
  }

  .projects-class {
    max-width: 100%;
    padding: 0;
  }

  .projects-class > h1 {
    font-size: 20px;
    text-align: center;
  }
}

/* Extra Small Devices (Older Phones) */
@media only screen and (max-width: 480px) {
  #projects {
    overflow: scroll;
    height: 180vh;
  }

  .project-items {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }

  .item {
    height: 92px;
  }

  .item-text {
    margin: 5px;
  }
  
  .item-text-title {
    padding: 0 0.2rem;
  }
  
  .item-text-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    word-wrap: break-word;
  }

  .item-text-wrap > h2 {
    font-size: 12px;
  }

  .item-text-wrap > p {
    display: none;
  }

  h5 {
    display: none;
  }

  .projects-class {
    max-width: 100%;
    padding: 0;
  }

  .projects-class > h1 {
    font-size: 20px;
    text-align: center;
  }
}

/* Larger Phones and Tablets (Landscape) */
@media only screen and (min-device-width: 769px) and (max-device-width: 1023px) and (orientation: landscape) {
  #projects {
    overflow: scroll;
    height: 150vh;
  }

  .item {
    height: 125px;
  }

  .item-text {
    margin: 5px;
  }
  
  .item-text-title {
    padding: 0 0.2rem;
  }
  
  .item-text-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    word-wrap: break-word;
  }

  .item-text-wrap > h2 {
    font-size: 12px;
  }

  .item-text-wrap > p {
    font-size: 10px;
  }

  h5 {
    font-size: 10px;
  }

  .projects-class {
    max-width: 100%;
    padding: 0;
  }

  .projects-class > h1 {
    font-size: 20px;
    text-align: center;
  }
}

/* XS / Smaller Phones (Landscape) */
@media only screen and (min-device-width: 480px) and (max-device-width: 768px) and (orientation: landscape) {
  .project-items {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

  .item {
    min-width: 200px;
  }

  .item-text {
    margin: 10px;
  }
  
  .item-text-title {
    padding: 0 0.5rem;
  }
  
  .item-text-wrap > h2 {
    font-size: 16px;
  }

  .item-text-wrap > p {
    font-size: 12px;
  }

  h5 {
    display: none;
  }
}

/* Small Devices (Phones, Tablets) */
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .project-items {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
  }

  .item {
    height: 125px;
  }

  .item-text {
    margin: 5px;
  }
  
  .item-text-title {
    padding: 0 0.2rem;
  }
  
  .item-text-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    word-wrap: break-word;
  }

  .item-text-wrap > h2 {
    font-size: 12px;
  }

  .item-text-wrap > p {
    display: none;
  }

  h5 {
    font-size: 10px;
  }

  .projects-class {
    max-width: 100%;
    padding: 0;
  }

  .projects-class > h1 {
    font-size: 20px;
    text-align: center;
  }
}

/* Default iPad Device (Landscape) */
@media only screen and (width: 1024px) and (orientation: landscape) {
  .project-items {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Handle Galaxy Fold Device (Landscape) */
@media only screen and (width: 653px) and (height: 280px) and (orientation: landscape) {
  #projects {
    overflow: scroll;
    height: 150vh;
  }

  .item {
    height: 92px;
  }

  .item-text {
    margin: 5px;
  }
  
  .item-text-title {
    padding: 0 0.2rem;
  }
  
  .item-text-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    word-wrap: break-word;
  }

  .item-text-wrap > h2 {
    font-size: 12px;
  }

  .item-text-wrap > p {
    display: none;
  }

  h5 {
    display: none;
  }

  .projects-class {
    max-width: 100%;
    padding: 0;
  }

  .projects-class > h1 {
    font-size: 20px;
    text-align: center;
  }
}
